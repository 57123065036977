import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import GlobalContext from "../../context/GlobalContext";

const Alert = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Alert Area --> */}
      <div className="alert-section py-9 bg-blackish-blue dark-mode-texts ">
        <Container>
          <Row className="justify-content-center">
            <Col xl="7" lg="8" md="10" sm="9" xs="10">
              <div className="alert-content d-flex flex-column flex-lg-row align-items-center justify-content-lg-center text-center">
                <span className="d-inline-flex mr-2 gr-text-color"></span>
                <span className="alert-text gr-text-9 gr-text-color">
                  <Trans>partner.question</Trans>{" "}
                  <a
                    href="/contact"
                    onClick={(e) => {
                      e.preventDefault();
                      gContext.toggleVideoModal();
                    }}
                    className="action-link gr-text-color gr-text-underline"
                  >
                    <Trans>partner.question-2</Trans>
                  </a>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Alert;

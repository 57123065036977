import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import imgS1 from "../../assets/image/l3/jpg/l3-service-img1.jpg";
import imgS2 from "../../assets/image/l3/jpg/l3-service-img2.jpg";
import imgS3 from "../../assets/image/l3/png/trustify-presentation.png";

const Services = () => {
  return (
    <>
      {/* <!-- Services Area --> */}
      <div className="service-section bg-default-2 pt-12 py-lg-23">
        <Container>
          <Row className="justify-content-center">
            <Col xl="6" lg="8" md="10">
              <div className="section-title text-center mb-11 mb-lg-15">
                <h2 className="title gr-text-4 mb-6">
                  <Trans>partner.service-title</Trans>
                </h2>
                <p className="gr-text-8 mb-0 px-lg-7 px-xl-0">
                  <Trans>partner.service-subtitle</Trans>
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg="4" md="6" sm="10" className="mb-13 mb-lg-0">
              <div className="service-card">
                <h3 className="card-title gr-text-7 mb-7">
                  <Trans>partner.service-st-1</Trans>
                </h3>
                <div className="img-thumb mb-8">
                  <img className="rounded-10 w-100" src={imgS3} alt="" />
                </div>
                <p className="excerpt gr-text-9 mb-6">
                  <Trans>partner.service-st-1-text</Trans>
                </p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="10" className="mb-13 mb-lg-0">
              <div className="service-card">
                <h3 className="card-title gr-text-7 mb-7">
                  <Trans>partner.service-st-2</Trans>
                </h3>
                <div className="img-thumb mb-8">
                  <img className="rounded-10 w-100" src={imgS1} alt="" />
                </div>
                <p className="excerpt gr-text-9 mb-6">
                  <Trans>partner.service-st-2-text</Trans>
                </p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="10" className="mb-13 mb-lg-0">
              <div className="service-card">
                <h3 className="card-title gr-text-7 mb-7">
                  <Trans>partner.service-st-3</Trans>
                </h3>
                <div className="img-thumb mb-8">
                  <img className="rounded-10 w-100" src={imgS2} alt="" />
                </div>
                <p className="excerpt gr-text-9 mb-6">
                  <Trans>partner.service-st-3-text</Trans>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Services;

import { graphql } from "gatsby";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";
import Alert from "../sections/landing10/Alert";
import Content1 from "../sections/landing10/Content1";
import Content3 from "../sections/landing10/Content3";
import CTA from "../sections/landing10/CTA";
import Hero from "../sections/landing10/Hero";
import Services from "../sections/landing10/Services";

const PARTNER = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "left",
          isFluid: true,
          button: "cta", // cta, account, null
          buttonText: "Get A Free Quote",
        }}
        footerConfig={{
          style: "style2", //style1, style2
        }}
      >
        <Header />
        <Hero />
        <Alert />
        <Content1 />
        <Services />
        <Content3 />
        <CTA />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default PARTNER;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import imgC from "../../assets/image/l3/png/trustify-partner-benefit-2.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div
        id="benefits"
        className="content-section pt-12 pb-10 bg-default-6 py-xl-25 "
      >
        <Container>
          <Row className="align-items-center justify-content-md-center">
            <Col lg="4" md="10">
              <div className="content-text text-center text-lg-left mb-9 mb-lg-0 pr-xl-11">
                <h2 className="title gr-text-5 mb-8">
                  <Trans>partner.stat-title-2</Trans>
                </h2>
                <p className="gr-text-8 mb-9">
                  <Trans>partner.stat-1</Trans>
                </p>
                <p className="gr-text-8 mb-9">
                  <Trans>partner.stat-2</Trans>
                </p>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="content-img rounded-8">
                <img className="w-100 rounded-8" src={imgC} alt="" />
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="stats-wrapper mt-6 mt-md-0 pl-xl-9">
                <div
                  className="single-stat py-6"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <h3 className="title gr-text-5 mb-5">
                    <Trans>partner.feature-1-title</Trans>
                  </h3>
                  <p className="gr-text-8 mb-0">
                    <Trans>partner.feature-1-1</Trans>
                    <strong>
                      <Trans>partner.feature-1-2</Trans>
                    </strong>
                    <Trans>partner.feature-1-3</Trans>
                    <strong>
                      <Trans>partner.feature-1-4</Trans>
                    </strong>
                    <Trans>partner.feature-1-5</Trans>
                  </p>
                </div>
                <div
                  className="single-stat py-6"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <h3 className="title gr-text-5 mb-5">
                    <Trans>partner.feature-2-title</Trans>
                  </h3>
                  <p className="gr-text-8 mb-0">
                    <Trans>partner.feature-2</Trans>
                  </p>
                </div>
                <div
                  className="single-stat py-6"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h3 className="title gr-text-5 mb-5">
                    <Trans>partner.feature-3-title</Trans>
                  </h3>
                  <p className="gr-text-8 mb-0">
                    <Trans>partner.feature-3</Trans>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
